import React from "react";
// Customizable Area Start
import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Typography,
  Tooltip as MuiTooltip,
} from "@material-ui/core";
import SideNav from "../../../components/src/SideNav";
import TopNavBar from "../../../components/src/TopNavBar";
import "./css/styles.css";
import {
  conversion,
  deals,
  leads,
  left_arrow,
  up_arrow,
  dollar,
  payment_pending,
  empty_graph,
  empty_conversion,
  empty_deals,
  empty_bars,
  prospecting_img,
  tracking_img,
  closed_lost_img,
  closed_won_img,
  closeIcon,
  site_logo,
  disqualified,
  slideimage1,
  slideimage2,
  slideimage3,
  slideimage4,
  leftArrow_grey,
  discovery_img,
  disqualified_img,
  leads_img,
  proposal_img,
  scope_discussion_img,
  solution_img,
  sql_img,
  partnersVideo,
  expandIcon,
  shrinkIcon,
  infoIcon,
} from "./assets";
import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { graphBars } from "../../leadmanagement/src/assets";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Filler
);

import { calendarFilterArray, getCalendarByValue, getCheckMarkIcon, getDashboardName, getDashboardValues, getDateRangeText, getZeroValue, getPipelineMapping } from "../../../components/src/commonUsage";
import { downArrow } from "../../assetsdashboard/src/assets"
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import 'react-calendar/dist/Calendar.css';
import moment from "moment";
import { checkMark } from "../../companycontactpage2/src/assets";
import 'chartjs-plugin-zoom';
import DashboardSkeleton from "./DashboardSkeleton";
import AllTimeGraph from "../../../components/src/allTimeGraph";
import DoughnutGraph from "../../../components/src/doughnutGraph";
import LineGraph from "../../../components/src/LineGraph";
import { upArrow } from "../../commissionreports2/src/assets";
import { down_fall } from "../../contractmanagement2/src/assets";
// Customizable Area End
import DashboardController, {
  Props,
  webConfigJSON
} from "./DashboardController.web";

export default class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  sliderData = [
    {
      id: 1,
      image: slideimage1.default,
      heading: 'Welcome to Builder Partners Hub!',
      sub_heading: 'Elevate your partnership game with our platform for streamlined partner relationship management.'
    },
    {
      id: 2,
      image: slideimage2,
      heading: 'Sell & earn by getting leads',
      sub_heading: 'Start monetizing your efforts by leveraging our platform to create valuable leads, maximizing your potential earnings with ease!'
    },
    {
      id: 3,
      image: slideimage3,
      heading: 'Use assets to reach more audience',
      sub_heading: 'Utilize assets effectively to broaden your audience reach and amplify your impact!'
    },
    {
      id: 4,
      image: slideimage4,
      heading: 'Unlocking Potential Together',
      sub_heading: 'One platform to create a reciprocal partnership ecosystem where everyone wins.'
    }
  ]
  conversionValue(value: number, calanderValue: any) {
    if (calanderValue === 'All Time') {
      return null;
    }

    // Check if value is defined and not null
    if (typeof value !== 'undefined' && value !== null) {
      const isPositive = value > 0; // Adjusted to consider 0 as neither positive nor negative
      const labelTextClass = isPositive ? "grid-up-text" : "grid-down-text";
      const arrowImage = isPositive ? upArrow : down_fall;
      const arrowAltText = isPositive ? "Up Arrow" : "Down Arrow";

      return (
        <>
          <label className={labelTextClass}>{value.toString().replace("-", "")}%</label>
          <label><img src={arrowImage} alt={arrowAltText} /></label>
        </>
      );
    } else {
      return null; // Return null if value is undefined or null
    }
  }
  renderSliderContent() {
    const componentHeight = () => {
      if (this.state.maxVideo)
        return "85vh"
      // if(this.state.sliderIndex===4)
      //   return "495px"
      return "410px"
    }
    return <Box className="welcome-content">
      <span className="skip-btn" onClick={() => this.closeModal()} style={{ display: this.state.maxVideo ? "none" : "block", marginBottom: "10px" }}>Skip</span>
      <div className="slider-container" style={{
        height: componentHeight()
      }}>
        {
          this.sliderData
            .map((filteredItem, index) => (
              <div
                key={index}
                style={{
                  transform: `translateX(${(index + 1 - this.state.sliderIndex) * 100}%)`, // Adjusting translation based on index,

                }}
                className={`slide-content ${filteredItem.id === this.state.sliderIndex ? 'slideractive' : ''}`}

              >
                {/* {index === 3 ?
                  this.VideoPlayer(filteredItem)
                  :
                  <> */}
                <Box className="slide-image-block">
                  <img src={filteredItem.image} alt="" />
                </Box>
                <Box className="slider-content-wrap">
                  <Typography style={{ fontSize: "24px", fontWeight: 500, paddingBottom: "12px" }} variant="h4">{filteredItem.heading}</Typography>
                  <Typography style={{ fontSize: "16px" }}>{filteredItem.sub_heading}</Typography>
                </Box>
                {/* </>
                } */}
              </div>
            ))
        }
      </div>
      {this.SliderPrevNextButton()}
    </Box>
  }

  SliderPrevNextButton = () => {
    return (
      <Box className="slider-action-wrapper">{
        this.state.sliderIndex === 4 ?
          <div className="next-button" onClick={() => this.closeModal()}>Get started</div>
          :
          <div className="next-button" onClick={() => this.handleSliderIndex('next')}>Next <span className="next-arrow"><img src={leftArrow_grey} alt="next" /></span></div>
      }
        {this.state.sliderIndex !== 1 && <span className="prev-arrow" onClick={() => this.handleSliderIndex('prev')}><img src={leftArrow_grey} alt="previous" /></span>}
        {!this.state.maxVideo && <div className="slider-dots">
          <span data-test-id="btn" onClick={() => this.handleSliderIndex(1)} className={this.state.sliderIndex === 1 ? "active" : ""}></span>
          <span data-test-id="btn" onClick={() => this.handleSliderIndex(2)} className={this.state.sliderIndex === 2 ? "active" : ""}></span>
          <span data-test-id="btn" onClick={() => this.handleSliderIndex(3)} className={this.state.sliderIndex === 3 ? "active" : ""}></span>
          <span data-test-id="btn" onClick={() => this.handleSliderIndex(4)} className={this.state.sliderIndex === 4 ? "active" : ""}></span>
        </div>}
      </Box>
    )
  }

  VideoPlayer = (filteredItem: {
    id: number;
    image: any;
    heading: string;
    sub_heading: string;
  }) => {
    return (
      <>
        <Box className="slider-content-wrap" style={{ display: this.state.maxVideo ? "none" : "block" }}>
          <Typography style={{ fontSize: "24px", fontWeight: 500, paddingBottom: "8px" }} variant="h4">{filteredItem.heading}</Typography>
          <Typography style={{ fontSize: "16px" }}>{filteredItem.sub_heading}</Typography>
        </Box>
        <Box style={{ position: "relative" }}>
          <Box position={"absolute"} top={16} right={16} zIndex={9999} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
            {this.state.maxVideo &&
              <button style={{
                padding: "8px 19px",
                background: "#fff",
                border: "none",
                borderRadius: "7.5px",
                marginRight: "8px",
                fontSize: "12px",
                fontWeight: 800,
                color: "#3C3E49",
                boxShadow: "0px 12px 30px 0px #651FFF1A"
              }}
                onClick={() => this.closeModal()}
              >
                Skip
              </button>}
            <button
              data-test-id="minMaxBtn"
              style={{
                padding: "8px",
                background: "#fff",
                border: "none",
                borderRadius: "7.5px",
                cursor: "pointer",
                boxShadow: "0px 12px 30px 0px #651FFF1A"
              }}
              onClick={() => { this.setState({ maxVideo: !this.state.maxVideo }) }}
            >
              <img src={this.state.maxVideo ? shrinkIcon : expandIcon} style={{ width: "14px", height: "14px" }} />
            </button>
          </Box>
          {
            this.state.sliderIndex === 4 &&
            <video controlsList="nodownload"
              controls
              autoPlay={this.state.sliderIndex === 4 ? true : false}
              style={{
                borderRadius: "10px",
                marginBottom: "10px",
                width: "100%",
                height: this.state.maxVideo ? "83vh" : "auto",
                objectFit: "cover"
              }}
            >
              <source src={filteredItem.image} type="video/mp4" />
            </video>
          }
        </Box>
      </>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { calendarMenu } = this.state;
    const sortCalendarOpen = Boolean(calendarMenu);
    return (
      //Merge Engine DefaultContainer
      <Box style={webStyles.container}>
        <Grid container>
          <Grid item xs={3} md={2} className="sidebar-wrapper">
            <SideNav {...this.props.navigation} />
          </Grid>
          <Grid item xs={9} md={10} className="main-content-wrapper">
            <Grid>
              <TopNavBar
                currentPage={{ name: "Dashboard", path: "dashboard" }}
              />
              <Box style={webStyles.mainContainer}>
                <div className="dashboard-nav-container no-border-bottom">
                  <div className="dashboard-heading">
                    <div>
                      <h4 className="heading-text">
                        Welcome Back, {getDashboardName(this.state.dashboardData)}
                      </h4>
                    </div>
                  </div>
                  <Grid className="dashboard-calendar-container">
                    <Grid item style={webStyles.rightAlign}>
                      <div className="date-range-wrapper">
                        <p className="date-range-text">
                          Show data over
                        </p>
                        <div className="display-flex ml-6">
                          <Button
                            id="select-button"
                            className="select-deal-button"
                            onClick={this.handleCalendarClick}
                            data-test-id={"btnCalendarMenuOpen"}
                            aria-haspopup="true"
                            disableRipple
                          >
                            <Typography className="select-stage-text">
                              {getCalendarByValue(this.state.calanderValue)}
                              <label className="dropdown-arrow">
                                <img src={downArrow} />
                              </label>
                            </Typography>
                          </Button>
                          <Menu
                            id="select-menu"
                            open={sortCalendarOpen}
                            anchorEl={calendarMenu}
                            aria-labelledby="select-button"
                            onClose={this.handleCalendarClose}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                            style={{
                              borderRadius: "8px",
                              top: "52px",
                            }}
                            PaperProps={{
                              className: 'date-range-menu-paper', // Add your custom class name here
                            }}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                          >
                            {
                              calendarFilterArray.map((item: any, index: any) => {
                                return <MenuItem data-test-id={`selectCalanderMenu${index}`} key={item.value} className="menu-item-text" onClick={() => this.handleCalendarSelect(item.value)}>{item.label}{getCheckMarkIcon(item.value, checkMark, this.state.calanderValue)}</MenuItem>
                              })
                            }


                          </Menu>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                  <div style={webStyles.rightAlign}>
                    <Button
                      onClick={() =>
                        this.navigateToNewDeal()
                      }
                      data-test-id={"btnRegisteredDealLead1"}
                      variant="contained"
                      color="primary"
                      className="heading-button"
                    >
                      Register referral
                    </Button>
                  </div>
                </div>
                <Grid className="dashboard-datepicker-container" container style={{ marginTop: "-4px" }}>
                  {this.state.openCalendar && (
                    <div className="calander-picker">
                      <div style={{ height: "390px", width: "351px" }}>
                        <div>
                          <Typography className="calander-range-text-label">Custom...</Typography>
                          <Typography className="calander-range-text-value">{moment(this.state.dateRangeValue[0]).format('MMM D')} - {moment(this.state.dateRangeValue[1]).format('MMM D YYYY')}</Typography>
                        </div>
                        <hr />
                        <div style={{ height: "337px" }}>
                          <DateRangePicker
                            isOpen={this.state.openCalendar}
                            value={this.state.dateValue}
                            onChange={this.handleDateChange}
                            className="daterange-picker-css"
                          />
                        </div>
                        <hr />
                      </div>
                      <div style={webStyles.rightAlign}>
                        <Button data-test-id="close-calendar" onClick={() => this.closeCalander()}>
                          <Typography className="cal-cancel-btn">
                            Cancel
                          </Typography>
                        </Button>
                        <Button data-test-id="get-filter-data" onClick={() => {
                          this.setState({ dateRangeValue: this.state.dateValue, openCalendar: false }, () => {
                            this.getFilteredData('normal')
                          })
                        }}>
                          <Typography className="cal-apply-btn">
                            Apply
                          </Typography>
                        </Button>
                      </div>
                    </div>
                  )}
                </Grid>
                {/* <TableSkeleton /> */}
                {this.state.loading ? (
                  // <Loader loading={this.state.loading} contentLoader={true} />
                  <DashboardSkeleton />

                ) : (
                  <>
                    <Grid className="dashboard-section-one">
                      {this.state.emptyState ? (
                        <Grid
                          item
                          xs={12}
                          md={12}
                          container
                          className="dashboard-section-empty-grid"
                        >
                          <Grid
                            className="lead-section-one-grid-empty-one"
                            item
                            xs={6}
                            md={6}
                          >
                            <Box>
                              <Typography className="grid-text-empty-heading">
                                Get started by adding referrals
                              </Typography>
                            </Box>
                            <Box>
                              <Typography className="grid-text-empty-sub-heading">
                                Add referrals to your account to get a
                                consolidated view of your data insights.
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid
                            className="lead-section-one-grid-empty-two"
                            item
                            xs={6}
                            md={6}
                          >
                            <img src={graphBars} />
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid item xs={12} md={12} container className="dashboard-section-one-grid-list">
                          <Grid
                            className="dashboard-section-one-grid"
                            item
                            xs={4}
                            md={4}
                          >
                            <Box>
                              <Typography className="grid-text-heading">
                                Overall pipeline
                                <Box className="tooltip-info-wrapper">
                                  <MuiTooltip
                                    classes={{ tooltip: 'custom-info-tooltip', }}
                                    title="Track the progress of your partnership activities with overall pipeline. View the number of referrals registered within a specified timeframe to gauge the volume and status of potential business prospects." placement="bottom" arrow>
                                    <img className="info-icon" src={infoIcon} alt="infoIcon"></img>
                                  </MuiTooltip >
                                </Box>
                              </Typography>
                            </Box>
                            <Box className="grid-counts">
                              <Grid item xs={12} md={12} container>
                                <Grid item className="icon-wrapper" style={{ marginRight: "12px" }}>
                                  <img src={leads} alt="Deals" />
                                </Grid>
                                <Grid item xs={5} className="grid-count-wrap">
                                  <Typography className="grid-count-text"
                                    style={{ cursor: 'pointer' }}
                                    data-test-id={"btnLeadDashboard-1"}
                                    onClick={() => this.navigateToLeadDashboard()}
                                  >
                                    {this.state.dashboardData.total_deals}
                                    {
                                      this.conversionValue(this.state.dashboardData.previous_total_deals, this.state.calanderValue)
                                    }
                                  </Typography>
                                  <Typography className="grid-count-sub-text">
                                    {getDateRangeText(this.state.calanderValue, this.state.dateRangeValue)}
                                  </Typography>
                                </Grid>
                                <Grid item className="grid-small-chart">
                                  <AllTimeGraph data={this.state.dashboardData.total_leads_graph} />
                                </Grid>
                              </Grid>
                            </Box>
                          </Grid>
                          <Grid
                            className="dashboard-section-one-grid"
                            item
                            xs={4}
                            md={4}
                          >
                            <Box>
                              <Typography className="grid-text-heading">
                                Closed
                                <Box className="tooltip-info-wrapper">
                                  <MuiTooltip
                                    classes={{ tooltip: 'custom-info-tooltip', }}
                                    title="Monitor the success of your deals with Closed Deals Metrics. Review the number of opportunities marked as 'Closed Won' to assess the effectiveness of your partnership strategies." placement="bottom" arrow>
                                    <img className="info-icon" src={infoIcon} alt="infoIcon"></img>
                                  </MuiTooltip >
                                </Box>
                              </Typography>
                              <Box className="grid-counts">
                                <Grid item xs={12} md={12} container>
                                  <Grid item className="icon-wrapper" style={{ marginRight: "12px" }}>
                                    <img src={deals} alt="Deals" />
                                  </Grid>
                                  <Grid item xs={5} className="grid-count-wrap">
                                    <Typography className="grid-count-text"
                                    >
                                      {
                                        this.state.dashboardData.total_closed_won_count
                                      }
                                      {
                                        this.conversionValue(this.state.dashboardData.previous_total_closed_won_count, this.state.calanderValue)
                                      }
                                    </Typography>
                                    <Typography className="grid-count-sub-text">
                                      {getDateRangeText(this.state.calanderValue, this.state.dateRangeValue)}
                                    </Typography>
                                  </Grid>
                                  <Grid item className="grid-small-chart">
                                    <AllTimeGraph data={this.state.dashboardData.total_lead_closed_won_graph} />
                                  </Grid>
                                </Grid>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid
                            className="dashboard-section-one-grid"
                            item
                            xs={4}
                            md={4}
                          >
                            <Box>
                              <Typography className="grid-text-heading">
                                Conversion value
                                <Box className="tooltip-info-wrapper">
                                  <MuiTooltip
                                    classes={{ tooltip: 'custom-info-tooltip', }}
                                    title="Track the total revenue generated by partners through registered deals to understand the financial impact of your partnership activities." placement="bottom" arrow>
                                    <img className="info-icon" src={infoIcon} alt="infoIcon"></img>
                                  </MuiTooltip >
                                </Box>
                              </Typography>
                            </Box>
                            <Box className="grid-counts">
                              <Grid item xs={12} md={12} container>
                                <Grid item className="icon-wrapper" style={{ marginRight: "12px" }}>
                                  <img src={conversion} alt="Deals" />
                                </Grid>
                                <Grid item xs={5} className="grid-count-wrap">
                                  <Typography className="grid-count-text">
                                    {this.state.dashboardData.conversion_value}
                                    {
                                      this.conversionValue(this.state.dashboardData.previous_conversion_value, this.state.calanderValue)
                                    }
                                  </Typography>
                                  <Typography className="grid-count-sub-text">
                                    {getDateRangeText(this.state.calanderValue, this.state.dateRangeValue)}
                                  </Typography>
                                </Grid>
                                <Grid item className="grid-small-chart">
                                  <AllTimeGraph data={this.state.dashboardData.conversion_value_graph} />
                                </Grid>
                              </Grid>
                            </Box>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                    <Grid className="chart-section">
                      <div className="chart-row">
                        <div className="chart-col">
                          <div className="chart-col-inner-wrap">
                            <div className="chart-outer-wrap pipeline-outer-wrap">
                              <Typography className="section-two-container-heading">
                                Pipeline distribution
                                <Box className="tooltip-info-wrapper">
                                  <MuiTooltip
                                    classes={{ tooltip: 'custom-info-tooltip', }}
                                    title="Visualize the distribution of your leads across different stages with the Pipeline Distribution pie chart. Gain insights into lead statuses and take informed actions to drive your partnership forward." placement="bottom" arrow>
                                    <img className="info-icon" src={infoIcon} alt="infoIcon"></img>
                                  </MuiTooltip >
                                </Box>
                              </Typography>
                              {this.state.emptyState ? (
                                <div className="no-content-wrapper">
                                  <div className="empty-content-wrap">
                                    <Box className="image-block">
                                      <img src={empty_deals} />
                                    </Box>
                                    <Typography className="no-conversion-text">
                                      No data added for this report
                                    </Typography>
                                    <div
                                      style={{
                                        display: "flex",
                                        marginTop: "16px",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <Button
                                        data-test-id={"btnRegisteredDealLead2"}
                                        onClick={() =>
                                          this.navigateToLeadManagement()
                                        }
                                        variant="contained"
                                        color="primary"
                                        className="heading-button"
                                      >
                                        Register referral
                                      </Button>
                                    </div>
                                  </div>
                                  <div className="chart-wrapper">
                                    <img
                                      src={empty_bars}
                                      style={{
                                        marginTop: "22px",
                                      }}
                                    />
                                  </div>
                                </div>
                              ) : (
                                <>
                                  <Grid className="chart-wrapper" container style={{ position: 'relative' }}>
                                    <Grid className="doughnut-chart-desc">
                                      <ul className="custom-legend-listing">
                                        {Object.keys(this.state.leads_graph).map((item: any) => {
                                          const info = getPipelineMapping[item]
                                          return <li className="legend-item">
                                            <Box className="legend-inner">
                                              <Typography>
                                                <img
                                                  src={info.image}
                                                  className="legend-img"
                                                />
                                                {info.name}
                                              </Typography>
                                              <div className="number-text">{this.state.leads_graph[item].count}</div>
                                            </Box>
                                          </li>
                                        })}
                                      </ul>
                                    </Grid>
                                    <Grid className="doughnut-chart-wrapper">
                                      <Grid className="container-two-sub-text">
                                        <Typography className="grid-two-text-heading">
                                          OVERALL PIPELINE
                                        </Typography>
                                      </Grid>
                                      <Box className="doughnut-chart" style={{ position: 'relative' }}>
                                        <DoughnutGraph data={this.state.dashboardData.leads_graph} />
                                        <div className="total-value">{this.state.dashboardData.total_deals == 0 ? "No referrals" : this.state.dashboardData.total_deals}</div>
                                      </Box>
                                    </Grid>

                                  </Grid>

                                </>
                              )}
                            </div >
                            <div className="view-more-block">
                              <Button disableRipple className="view-more-btn"
                                data-test-id={"btnLeadDashboard3"}
                                onClick={() =>
                                  this.navigateToLeadDashboard()
                                }
                              >
                                View all
                                <label className="small-icon">
                                  <img
                                    src={left_arrow}
                                    alt="View all"
                                  />
                                </label>
                              </Button>
                            </div>
                          </div >
                        </div >
                        <div className="chart-col" >
                          <div className="chart-col-inner-wrap">
                            <div className="chart-outer-wrap">
                              <Typography className="section-two-container-heading">
                                Conversions trend
                                <Box className="tooltip-info-wrapper">
                                  <MuiTooltip
                                    classes={{ tooltip: 'custom-info-tooltip', }}
                                    title="Gain insights into your conversion trends with the Conversion Trends Chart. Track deals won over a specific time period to understand your partnership's performance and identify areas for improvement." placement="bottom" arrow>
                                    <img className="info-icon" src={infoIcon} alt="infoIcon"></img>
                                  </MuiTooltip >
                                </Box>
                              </Typography>
                              <Grid className="line-chart">
                                {this.state.emptyState || this.state.dashboardData.conversion_value_graph.length === 0 ? (
                                  <div className="empty-line-content-wrap">
                                    <div className="empty-line-inner-wrap">
                                      <Box className="image-block">
                                        <img src={empty_conversion} />
                                      </Box>
                                      <Typography className="no-conversion-text">
                                        No conversions available
                                      </Typography>
                                    </div>
                                    <div className="empty-image-wrap">
                                      <img
                                        src={empty_graph}
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  <LineGraph data={this.state.dashboardData.over_all_closed_conversion_graph} dateRange={this.state.dateRangeValue} calanderValue={this.state.calanderValue} />
                                )}
                              </Grid>
                            </div>
                            <div className="view-more-block">

                              <Button disableRipple className="view-more-btn"
                                data-test-id={"btnLeadDashboard-4"}
                                onClick={() => this.navigateToLeadDashboard()}

                              >
                                View more
                                <label className="small-icon">
                                  <img
                                    src={left_arrow}
                                    alt="View Deals and leads"
                                  />
                                </label>
                              </Button>
                            </div>
                          </div>
                        </div >
                      </div >
                    </Grid >
                    <Grid className="dashboard-section-three">
                      {this.state.emptyState ? (
                        <Grid
                          item
                          xs={12}
                          md={12}
                          container
                          className="dashboard-section-empty-grid"
                        >
                          <Grid
                            className="lead-section-one-grid-empty-one height-lg"
                            item
                            xs={6}
                            md={6}
                          >
                            <Box>
                              <Typography className="grid-text-empty-heading">
                                No commissions yet
                              </Typography>
                            </Box>
                            <Box>
                              <Typography className="grid-text-empty-sub-heading">
                                Add referrals to your account to get your
                                commission report.
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid
                            className="lead-section-one-grid-empty-two"
                            item
                            xs={6}
                            md={6}
                          >
                            <img src={payment_pending} className="adjust-spacing" />
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid className="commission-section">
                          <Box>
                            <Typography className="section-three-heading">
                              Commission report
                              <Box className="tooltip-info-wrapper">
                                <MuiTooltip
                                  classes={{ tooltip: 'custom-info-tooltip', }}
                                  title="Stay informed about your commission earnings and commitments with the Commission Overview. Track metrics such as closed deals and commission earned to optimize your partnership strategy." placement="bottom" arrow>
                                  <img className="info-icon" src={infoIcon} alt="infoIcon"></img>
                                </MuiTooltip >
                              </Box>
                            </Typography>
                          </Box>

                          <Grid
                            item
                            xs={12}
                            md={12}
                            container
                            className="section-container"
                          >
                            <Grid
                              item
                              xs={6}
                              md={6}
                              container
                              className="commission-inner-row section-grid-one"
                            >
                              <Grid className="commission-inner-col col-one">
                                <Box>
                                  <Typography className="grid-three-text-heading">
                                    CLOSED
                                  </Typography>
                                  <Box className="grid-counts">
                                    <Typography className="grid-count-three-text">
                                      {this.state.dashboardData.total_closed_won_count}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Grid>

                              <Grid className="commission-inner-col col-two">
                                <Box>
                                  <Typography className="grid-three-text-heading">
                                    CONVERSION VALUE
                                  </Typography>
                                  <Box className="grid-counts">
                                    <Typography className="grid-count-three-text">
                                      {this.state.dashboardData.conversion_value}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Grid>

                              <Grid className="commission-inner-col col-three">
                                <Box>
                                  <Typography className="grid-three-text-heading">
                                    VALUE IN PIPELINE
                                  </Typography>
                                  <Box className="grid-counts">
                                    <Typography className="grid-count-three-text">
                                      {this.state.dashboardData.value_in_paipeline}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              xs={6}
                              md={6}
                              container
                              className="commission-secondary-row"
                            >
                              <Grid
                                className="commission-secondary-col col-lg"
                                item
                                xs={8}
                                md={8}
                              >
                                <Grid item xs={12} md={12} container>
                                  <Grid item xs={12} md={12} className="col-inner-content">
                                    <i className="image-wrap">
                                      <img src={dollar} alt="Deals" />
                                    </i>
                                    <div className="content-block">
                                      <Typography className="grid-three-text-heading">
                                        Commission earned
                                      </Typography>
                                      <Box className="grid-counts">
                                        <Grid item xs={12} md={12} container>
                                          <Grid item xs={6} md={6}>
                                            <Typography className="grid-count-three-text-one">
                                              {this.state.dashboardData.commission_earned}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      </Box>
                                    </div>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid
                                className="commission-secondary-col col-sm"
                                item
                                xs={4}
                                md={4}
                              >
                                <Box>
                                  <Typography className="grid-three-text-heading">
                                    COMMITMENT
                                  </Typography>
                                </Box>
                                <Box className="grid-counts">
                                  <Typography className="grid-count-three-text">
                                    {getZeroValue(this.state.dashboardData.min_commit_value)}
                                  </Typography>
                                  {/* <Typography className="on-track-label">
                                    ON TRACK
                                  </Typography> */}
                                </Box>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                      <div className="view-more-block">
                        <Button disableRipple className="view-more-btn"
                          data-test-id={"btnLeadDashboard-5"}
                          onClick={() => this.navigateToCommissionDashboard()}
                        >
                          View more
                          <label className="small-icon">
                            <img
                              src={left_arrow}
                              alt="View Deals and leads"
                            />
                          </label>
                        </Button>
                      </div>
                    </Grid>
                  </>
                )}
              </Box >
            </Grid >
          </Grid >
        </Grid >


        <Modal
          open={this.state.firstTimeUserModal}
          onClose={() => this.closeModal()}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          BackdropProps={{
            className: "custom-modal-backdrop"
          }}
          style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
        >
          <Box
            className="modal-body"
            style={{
              width: "510px",
              background: "#fff",
              padding: this.state.maxVideo ? "12px" : "32px",
              borderRadius: "8px",
              outline: "none",
              boxShadow: "0px 12px 24px 0px #00000026",
              transition: "all 0.5s ease 0s"
            }}>
            {this.renderSliderContent()}
          </Box>
        </Modal >


        <Modal
          open={this.state.addMemberModal}
          onClose={() => this.closeModal()}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="custom-modal confirmation-modal modal-sm"
          BackdropProps={{
            className: "custom-modal-backdrop"
          }}
        >
          <Box className="modal-body">
            <Box>
              <IconButton disableRipple className="close-btn" onClick={() => this.closeModal()}><img src={closeIcon} alt="close" /></IconButton>
            </Box>
            <Box className="welcome-content">
              <Box className="logo-block">
                <img src={site_logo} alt="" />
              </Box>
              <Box className="welcontent-wrap">
                <Typography variant="h4">{`Welcome ${this.state.dashboardData.partner.name}`}</Typography>
                <Typography>Unlock profits through collaboration! Introducing Builder.ai's Partnership Platform – earn commissions by referring leads. Join us in shaping success!</Typography>
              </Box>
              <Box className="btn-wrap">
                <Button onClick={() => this.closeModal()} className="custom-btn green-btn full-width">Continue</Button>
              </Box>
            </Box>
          </Box>
        </Modal>
      </Box >
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const webStyles = {
  container: {
    background: "#F8F9FA",
  },
  mainContainer: {
    padding: "16px 0",
  },
  rightAlign: {
    alignItems: "center",
    justifyContent: "flex-end",
    display: "flex",
  },
};
// Customizable Area End
